<template>
  <div class="menuopen" :class="{'dp_none': ($store.getters['scroll-direction/isScrollDown'] && $route.path === '/goods')}">
    <div class="top_banner">
      <div class="top_bnslide wrapper">
        <!-- Swiper -->
        <client-only>
          <swiper :options="swiperOptions">
            <swiper-slide
              v-for="(banner, index) in textBannerRollingList"
              :key="`banner_${index}`"
            >
              <p class="bn_info">{{banner.bnrText}}</p>
              <a
                :href="getUrl(banner.bnrLinkUrl)"
              >
                {{ banner.bnrHtml }}
              </a>
            </swiper-slide>
          </swiper>
          <div class="top_info mgl10">
            <ul>
              <li v-if="$store.state.session.isMember === false"><nuxt-link to="/login">Login</nuxt-link></li>
              <li v-if="$store.state.session.isMember === false"><nuxt-link to="/join">Join</nuxt-link></li>
              <li v-if="$store.state.session.isMember === true"><nuxt-link to="/my-page">Mypage</nuxt-link></li>
              <li v-if="$store.state.session.isMember === true"><nuxt-link to="/my-page/order">Order</nuxt-link></li>
              <li v-if="$store.state.session.isMember === true"><a @click="onLogoutButtonClicked()">Logout</a></li>
              <li><nuxt-link to="/footer/store">Store</nuxt-link></li>
              <li><nuxt-link to="/footer/story">About</nuxt-link></li>
            </ul>
          </div>
        </client-only>
        <!-- //Swiper -->
        <!-- <div class="swiper-wrapper">
          <div class="swiper-slide" >
            <a v-href="bnrLinkUrl" v-html="bnrHtml">회원가입시 바로 사용할 수 있는 20% 쿠폰을 발급해드립니다.</a>
          </div>
        </div>
        <div class="swiper-button-prev top_bnprev"></div>
        <div class="swiper-button-next top_bnnext"></div> -->
      </div>
    </div>
    <header class="header_wrap">
      <div class="wrapper">
        <!--snb -->
        <div class="header_snb">
          <h1 class="logo">
            <nuxt-link
              :to="{ path: '/' }"
              class="bt_logo"
            >
              까웨(K-WAY)
            </nuxt-link>
          </h1>
          <div class="header_right">
            <ul>
              <div class="header_search_wrap">
                <div class="header_search" @click="openSearchModal()">
                  <!--<input type="search" />
                  <nuxt-link to="/" class="bt_search_h">SEARCH</nuxt-link>-->
                </div>
              </div>
              <!-- <li class="login_state" v-if="$store.state.session.isMember === true"><a @click="onLogoutButtonClicked()">Logout</a></li> -->
              <!--<li @mouseover="isShowMyMenu = true" @mouseout="isShowMyMenu = false">
                <nuxt-link to="/my-page" class="bt_user_tab">
                  <p class="txt_indt">MY PAGE</p>
                  <span
                    class="mypage_badge"
                    v-if="$store.state.session.profile.giftReceiveCount > 0"
                  >
                    {{ $store.state.session.profile.giftReceiveCount }}
                  </span>
                  선물 하기 수 -->
                <!--</nuxt-link>
                <ul class="my_smmenu" v-show="isShowMyMenu">
                  <li v-if="$store.state.session.isMember === false"><nuxt-link to="/login">로그인</nuxt-link></li>
                  <li v-if="$store.state.session.isMember === false"><nuxt-link to="/join">회원가입</nuxt-link></li>
                  <li v-if="$store.state.session.isMember === true"><a @click.prevent="onClickLogout()">Logout</a></li>
                  <li><a href="/my-page">마이페이지</a></li>
                  <li><a href="/my-page/order">주문조회</a></li>
                  <li><a href="/my-page/gift/receive">선물함</a></li>
                </ul>
              </li> -->
              <li>
                <!-- <nuxt-link
                  to="/cart"
                  class="bt_cart"
                >
                  <p class="txt_indt">CART</p>
                  <span v-if="$store.state.session.profile.cartGoodsCount > 0" class="cart_badge">
                    {{ $store.state.session.profile.cartGoodsCount }}
                  </span>
                </nuxt-link> -->
                <div
                  class="bt_cart"
                  @click.prevent="openCartModal()"
                >
                  <p class="txt_indt">CART</p>
                  <span
                    v-if="$store.state.session.profile.cartGoodsCount > 0"
                    class="cart_badge"
                  >
                    {{ $store.state.session.profile.cartGoodsCount }}
                  </span>
                </div>
                <cartMini @openCartModal="openCartModal" :display="miniCartDisplay" :on-order-start="onOrderStart"/>
              </li>
            </ul>
          </div>
        </div>
        <!--//snb -->
        <!-- GNB -->
        <div class="header_gnb">
          <nav>
            <h2 class="hidden">메인 네비게이션</h2>
            <ul class="gnb">
              <li class="hasmenu">
                <nuxt-link to="/main/new">NEW</nuxt-link>
              </li>
              <li class="hasmenu">
                <nuxt-link to="/main/best">BEST</nuxt-link>
                <!-- <nuxt-link to="/main/best" class="active">BEST</nuxt-link> -->
              </li>
              <li class="hasmenu">
                <!-- <nuxt-link to="/main/sale">SALE</nuxt-link> -->
                <!--a :href="`/promotion/574`">CHALLENGE</a-->
                <nuxt-link to="/promotion/643" style="color: #f30000;">HOLIDAY GIFT</nuxt-link>
              </li>
              <li class="hasmenu" @mouseover="doShowCategoryMan" @mouseout="doShowCategoryMan">
                <a href="/category?id=10&gender=M" data-gender="M">MEN</a>
              </li>
              <li class="hasmenu" @mouseover="doShowCategoryWoman" @mouseout="doShowCategoryWoman">
                <a href="/category?id=10&gender=F" data-gender="F">WOMEN</a>
                <!-- <nuxt-link to="/">WOMEN</nuxt-link> -->
              </li>
              <li class="hasmenu" @mouseover="doShowCategoryKids" @mouseout="doShowCategoryKids">
                <a href="/category?id=10&gender=K" data-gender="K">KIDS</a>
                <!-- <nuxt-link to="/">KIDS</nuxt-link> -->
              </li>
              <!--<li class="hasmenu">
                <nuxt-link to="/special">SPECIAL</nuxt-link>
              </li>-->
              <li class="hasmenu">
                <!-- <nuxt-link to="/main/sale">SALE</nuxt-link> -->
                <a :href="`/category?id=15`">OUTLET</a>
              </li>
              <li class="hasmenu">
                <!-- 하드코딩 ㅈㅅ ㅎㅎ -->
                <p style="display: block;position:relative;padding:28px 15px;color:#20202c;font-size:14px;font-weight: 400;">|</p>
              </li>
              <li class="hasmenu">
                <!-- <nuxt-link to="/main/sale">SALE</nuxt-link> -->
                <a :href="`/footer/story`" style="font-weight: 400;">K-WAY STORY</a>
              </li>
              <li class="hasmenu">
                <!-- <nuxt-link to="/main/sale">SALE</nuxt-link> -->
                <a :href="`/footer/collection`" style="font-weight: 400;">COLLECTION</a>
              </li>
            </ul>
          </nav>
        </div>
        <!-- GNB -->
      </div>

      <!-- 카테고리 -->
      <div id="cate_menu" class="all_menu" v-show="isShowCategory" @mouseover="doShowCategory" @mouseout="doShowCategory">
          <div class="promenu_wrap">
            <div class="left_promenu">
            <ul class="drop_menu first">
                <li><a :href="`/category?id=10`">ALL MENU</a></li>
                <li v-show="isShowMan"><a :href="`/category?id=10&gender=M`">ALL MEN</a></li>
                <li v-show="isShowWoman"><a :href="`/category?id=10&gender=F`">ALL WOMEN</a></li>
                <li v-show="isShowKids"><a :href="`/category?id=10&gender=K`">ALL KIDS</a></li>
            </ul>

            <template v-for="(category, index) in categories">
                <ul class="drop_menu" :key="index" v-show="isShowMan && category.goodsCntUpMan > 0">
                <li><a :href="`/category?id=${category.dispClsfNo}&gender=${gender}`">{{ category.dispClsfNm }}</a></li>
                <template v-for="(subCategory, subIndex) in category.subDispCateList">
                    <li v-show="subCategory.goodsCntMan > 0" :key="`category_${index}_sub_${subIndex}`">
                    <a :href="`/category?id=${subCategory.dispClsfNo}&gender=${gender}`">{{ subCategory.dispClsfNm }}</a>
                    <!-- <nuxt-link
                    :to="{ path: '/category', query: { id: subCategory.dispClsfNo } }"
                    @click.native="closeCategory()"
                    >
                    {{ subCategory.dispClsfNm }}
                    </nuxt-link> -->
                    </li>
                </template>
                </ul>
            </template>
            <template v-for="(category, index) in categories">
                <ul class="drop_menu" :key="index + 100" v-show="isShowWoman && category.goodsCntUpWoman > 0">
                <li><a :href="`/category?id=${category.dispClsfNo}&gender=${gender}`">{{ category.dispClsfNm }}</a></li>
                <template v-for="(subCategory, subIndex) in category.subDispCateList">
                    <li v-show="subCategory.goodsCntWoman > 0" :key="`category_${index}_sub_${subIndex}`">
                    <a :href="`/category?id=${subCategory.dispClsfNo}&gender=${gender}`">{{ subCategory.dispClsfNm }}</a>
                    <!-- <nuxt-link
                    :to="{ path: '/category', query: { id: subCategory.dispClsfNo } }"
                    @click.native="closeCategory()"
                    >
                    {{ subCategory.dispClsfNm }}
                    </nuxt-link> -->
                    </li>
                </template>
                </ul>
            </template>

            <template v-for="(category, index) in categories">
                <ul class="drop_menu" :key="index + 1000" v-show="isShowKids && category.goodsCntUpKids > 0">
                <li><a :href="`/category?id=${category.dispClsfNo}&gender=${gender}`">{{ category.dispClsfNm }}</a></li>
                <template v-for="(subCategory, subIndex) in category.subDispCateList">
                    <li v-show="subCategory.goodsCntKids > 0" :key="`category_${index}_sub_${subIndex}`">
                    <a :href="`/category?id=${subCategory.dispClsfNo}&gender=${gender}`">{{ subCategory.dispClsfNm }}</a>
                    <!-- <nuxt-link
                    :to="{ path: '/category', query: { id: subCategory.dispClsfNo } }"
                    @click.native="closeCategory()"
                    >
                    {{ subCategory.dispClsfNm }}
                    </nuxt-link> -->
                    </li>
                </template>
                </ul>
            </template>
          </div>
          <ul class="drop_menu one">
            <li><a v-bind:href="`/footer/story`">K-WAY STORY</a></li>
            <li><a v-bind:href="`/promotion`">기획전</a></li>
            <li><a v-bind:href="`/kway-people`">K-WAY PEOPLE</a></li>
            <li><a v-bind:href="`/event`">EVENT</a></li>
            <li><a v-bind:href="`/customer/main`">CS CENTER</a></li>
            <li><a v-bind:href="`/footer/store`">STORE</a></li>
            <li><a v-bind:href="`/footer/collection`">COLLECTION</a></li>
            <li><a v-bind:href="`/main/review`">REVIEW</a></li>


            <!--
            <li><a v-bind:href="`/event`">EVENT</a></li>
            <li><a v-bind:href="`/promotion`">기획전</a></li>
            -->
          </ul>

        </div>
      </div>
      <!-- //카테고리 -->
    </header>
  </div>
</template>

<script>
import Common from "@/assets/mixins/common";
import DesktopSearchModal from "@/components/commons/modal/DesktopSearchModal.vue";
// import DesktopCartModal from "~/components/commons/modal/CartModal.vue";
import CartMini from "@/components/pages/cart/mini/Desktop.vue";
// import CartMini from "@/pages/cart/mini/index";

export default {
  components: {
    DesktopSearchModal,
    // DesktopCartModal,
    CartMini,
  },

  mixins: [ Common ],

  data: () => ({
    categories: [],
    manEmptyList: [],
    womanEmptyList: [],
    kidsEmptyList: [],
    isShowCategory: false,
    isShowMan: false,
    isShowWoman: false,
    isShowKids: false,
    textBannerList: [],
    textBannerRollingList: [],
    textBannerIndex: 1,
    textBannerRolling: undefined,
    giftReceiveCount : 0,
    miniCartDisplay: 'none',
    swiperOptions: {
      direction: 'vertical',
      slidesPerView: 1,
      loop: true,
      speed : 1000,
      autoHeight: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    },
    gender:"M",
    isShowMyMenu: false,
    onOrderStart: false,
  }),

  watch: {
    "$store.state.category.categories": {
      immediate: true,
      handler(value) {
        this.categories = value.filter(x => x.dispLvl === 1 && x.dispClsfCd == "10" && x.cid);
         //console.log(this.categories)

      },
    },
  },

  // async created() {
  //   const [receiveCount, sendCount] = await Promise.all([this.$axios.$get('/api/gift/receive/count'), this.$axios.$get('/api/gift/send/count')]);
  //    this.$store.commit("session/updateGiftCount",receiveCount);
  // },

  async mounted() {
    window.addEventListener('scroll', this.rAFWrapper(this.handleScroll))

    try {
      this.textBannerList = await this.$axios.$get("/api/main/text-banner");
      this.textBannerRollingList = this.textBannerList;
      // console.log('textBannerRollingList : ', this.textBannerRollingList);
      // if (this.textBannerList != undefined && this.textBannerList.length > 1) {
      //   this.textBannerRolling = setInterval(this.textBannerRollingEventHandler, 10000);
      //   }
    } catch (e) {
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.rAFWrapper(this.handleScroll))
  },

  methods: {
    rAFWrapper(callback) {
      let tick = false

      return function trigger() {
        if (tick) {
          return
        }

        tick = true
        return requestAnimationFrame(function task() {
          tick = false
          return callback()
        })
      }
    },
    handleScroll: function () {
      if (this.$route.path !== '/goods') return
      let cp = window.scrollY;
      if (this.isMainCheck) {
        if (cp > 0) {
          this.isMoveScroll = true;
        } else {
          this.isMoveScroll = false;
        }
      }
      const lp = this.lastScrollY
      if (lp >= 0 && cp > lp) {
        this.$store.commit('scroll-direction/set', 'DOWN')
      } else {
        this.$store.commit('scroll-direction/set', 'UP')
      }
      this.lastScrollY = cp
    },
    async onLogoutButtonClicked() {
      if (this.$store.state.session.isLotteMembers) {
        try {
          const acesTkn = this.$store.state.session.lMembersAcesTkn
          const sso = this.$ssoLib.initSsoLogout({ acesTkn })
    
          sso.callLogout({
            callback: (rspDta) => {
              // 로그인 상태 변경
              console.log('logout !!');
              console.log(rspDta);
              console.log(this.$store)
              this.$store.commit("session/clearLMembersTkn");
            }
          });
        } catch (error) {
          // console.error(error)
        }
      }
      
      await this.$axios.$post("/api/logout");
      this.$store.commit("session/clear");
      try {
        window.cremaAsyncInit = function () {
          crema.init(null, null);
        };
        window.cremaAsyncInit();
      } catch (error) {
        // console.log(`Crema init error\n> ${error}`)
      }
      window.location = "/";
      // await this.$router.push("/", () => window.location.reload());

    },

    onClickDropCategoryMenu(gender) {
      var cateMenu = document.getElementById('cate_menu');
      this.gender = gender;
      // this.isShowCategory = true;
      cateMenu.style.display = 'block';
    },

    closeCategory() {
        var cateMenu = document.getElementById('cate_menu');
        cateMenu.style.display = 'none';
        // this.isShowCategory = false;
    },

    async openSearchModal() {
      await this.$_rk.load();
      let popular = await this.$axios.$get("/diquest/popular");
      this.$modal.show(
        DesktopSearchModal,
        {
          popular
        },
        {
          width: "100%",
          height: "100%",
        }
      );
    },
    openCartModal(){
      // this.$modal.show(
      //   DesktopCartModal,
      //   {
      //   },{
      //     width: "50%",
      //     height: "100%",
      //   }
      // )
      this.$router.push('/cart');
      return;

      /*
      //미니카트 안씀
      this.onOrderStart = false;
      if(this.miniCartDisplay === 'none'){
        this.miniCartDisplay = 'block';
      }else{
        this.miniCartDisplay = 'none';
      };
      */
    },
    textBannerRollingEventHandler() {
      let nextIndex = this.textBannerIndex + 1;
      if (nextIndex >= this.textBannerList.length) nextIndex = 0;
      this.textBannerIndex = nextIndex;

      let t = 0;
      let animation = setInterval(() => {
        let step = 43 / 200;
        t = t - step;
        try {
          // this.$refs["textBanner"].style.top = t + "px";
        } catch (e) {
        }

        if (t <= -43) {
          clearInterval(animation);
          // this.$refs["textBanner"].style.top = 0;
          this.textBannerRollingList.shift();
          if (this.textBannerList[this.textBannerIndex]) {
            this.textBannerRollingList.push(this.textBannerList[this.textBannerIndex]);
          }
        }
      }, 1);
    },
    doShowCategoryMan(e) {
      if (e.type == 'mouseout') {
        // setTimeout(() => {
          this.isShowCategory = false;
        // }, 500);
      } else {
        const gender = e.target.getAttribute("data-gender");
        if (gender) {
          this.gender = gender;
        }

        this.isShowCategory = true;
        this.isShowMan = true;
        this.isShowWoman = false;
        this.isShowKids = false;
      }

      // console.log(e)
      // console.log(e.target)
    },

    doShowCategoryWoman(e) {
      if (e.type == 'mouseout') {
        // setTimeout(() => {
          this.isShowCategory = false;
        // }, 500);
      } else {
        const gender = e.target.getAttribute("data-gender");
        if (gender) {
          this.gender = gender;
        }

        this.isShowCategory = true;
        this.isShowWoman = true;
        this.isShowMan = false;
        this.isShowKids = false;
      }

      // console.log(e)
      // console.log(e.target)
    },

    doShowCategoryKids(e) {
      if (e.type == 'mouseout') {
        // setTimeout(() => {
          this.isShowCategory = false;
        // }, 500);
      } else {
        const gender = e.target.getAttribute("data-gender");
        if (gender) {
          this.gender = gender;
        }

        this.isShowCategory = true;
        this.isShowKids = true;
        this.isShowMan = false;
        this.isShowWoman = false;
      }

      // console.log(e)
      // console.log(e.target)
    },

    doShowCategory(e) {
      if (e.type == 'mouseout') {
        // setTimeout(() => {
          this.isShowCategory = false;
        // }, 500);
      } else {
        const gender = e.target.getAttribute("data-gender");
        if (gender) {
          this.gender = gender;
        }

        this.isShowCategory = true;
      }

      // console.log(e)
      // console.log(e.target)
    }
  }
};
</script>
